<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <h6 class="mb-2 text-black">Mill</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="millModel" multiple column @change="(event) => millOnChange(event)"> 
                                        <v-chip label v-for="item in millLists" :key="item.mill_id" :value="item.mill_id" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white"  small dense>
                                            {{item.mill_name}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Regular Moving</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="regularModel" column @change="(event) => regularOnChange(event)"> 
                                        <v-chip label v-for="item in regularLists" :key="item.value" :value="item.value" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.text}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Category</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="categoryModel" column @change="(event) => categoryOnChange(event)"> 
                                        <v-chip label v-for="item in categoryLists" :key="item.category" :value="item.category" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.category}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="mb-1 text-black">Material</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="materialModel" multiple column @change="(event) => materialOnChange(event)"> 
                                        <v-chip label v-for="item in materialLists" :key="item.jenis" :value="item.jenis" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.jenis}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Grade</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="gradeModel" multiple column @change="(event) => gradeOnChange(event)"> 
                                        <v-chip label v-for="item in gradeLists" :key="item.grade_id" :value="item.grade_id" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.grade_id}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="mb-1 text-black">Color</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="colorModel" multiple column @change="(event) => colorOnChange(event)"> 
                                        <v-chip label v-for="item in colorLists" :key="item.NamaWarna" :value="item.NamaWarna" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.NamaWarna}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="mb-1 text-black">Thickness</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="thicknessModel" multiple column @change="(event) => thicknessOnChange(event)"> 
                                        <v-chip label v-for="item in thicknessLists" :key="item.thick" :value="item.thick" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{ item.thick }} mm
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Width</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="widthModel" multiple column @change="(event) => widthOnChange(event)"> 
                                        <v-chip label v-for="item in widthLists" :key="item.width" :value="item.width" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{ $store.getters.convertToCurrency(item.width) }} mm
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Quality</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="qualityModel" multiple column @change="(event) => qualityOnChange(event)"> 
                                        <v-chip label v-for="item in qualityLists" :key="item.quality_id" :value="item.quality_id" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.quality_id}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <h6 class="mb-1 text-black">Availability</h6>
                                <div class="scrollDown">
                                    <v-chip-group v-model="availabilityModel" column> 
                                        <v-chip label v-for="item in availabilityLists" :key="item.value" :value="item.value" class="ma-1 fontCilik" color="blue lighten-5 text-black" active-class="blue darken-4 text-white" small dense>
                                            {{item.text}}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
                            <div class="col-lg-6 col-xs-6">
                                <v-radio-group v-model="rdOrderOnlyModel" row>
                                    <v-radio dense label="All Material" value="N"></v-radio>
                                    <v-radio dense label="Only order" value="Y"></v-radio>
                                    <v-radio dense label="Shortage" value="S"></v-radio>
                                </v-radio-group>
                            </div>
                            <div class="col-lg-6 text-right">
                                <v-btn class="mr-4" color="primary" elevation="2" small @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-12 cardResult">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3 col-xs-6">
                                <div class="card" style="width: 18rem;">
                                    <div class="card-header fontCilik text-secondary text-center">
                                        Total On Hand
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item fontCilik"> Length : {{ $store.getters.convertToCurrency(this.totalLengthOnHand) }} m </li>
                                        <li class="list-group-item fontCilik"> Weight : {{ $store.getters.convertToCurrency(this.totalWgtOnHand) }} T</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-xs-6">
                                <div class="card" style="width: 18rem;">
                                    <div class="card-header fontCilik  text-secondary text-center">
                                        Total Reserve
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item fontCilik"> Length : {{ $store.getters.convertToCurrency(this.totalLengthReserve) }} m </li>
                                        <li class="list-group-item fontCilik"> Weight : {{ $store.getters.convertToCurrency(this.totalWgtReserve) }} T </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 col-xs-6">
                                <div class="card" style="width: 18rem;">
                                    <div class="card-header fontCilik text-secondary text-center">
                                        Total Unrelease
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item fontCilik"> Length : {{ $store.getters.convertToCurrency(this.totalLengthUnrelease) }} m </li>
                                        <li class="list-group-item fontCilik"> Weight : {{ $store.getters.convertToCurrency(this.totalWgtUnrelease) }} T </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-3 col-xs-6">
                                <div class="card" style="width: 18rem;">
                                    <div class="card-header fontCilik text-secondary text-center">
                                        Total Available
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item fontCilik"> Length : {{ $store.getters.convertToCurrency(this.totalLengthAvail) }} m </li>
                                        <li class="list-group-item fontCilik"> Weight : {{ $store.getters.convertToCurrency(this.totalWgtAvail) }} T </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-12 col-xs-6">
                                <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" :item-class="row_classes" dense>
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <!-- <div class="d-flex w-25 text-right">
                                                <v-btn class="mt-3" color="green" elevation="2" small rounded @click="exportToExcel()" dark>EXPORT TO EXCEL</v-btn>
                                            </div> -->
                                            <div class="row">
                                                <div class="col-12 text-right">
                                                    <v-btn class="mt-3" color="green" elevation="2" small rounded @click="exportToExcel()" dark>EXPORT TO EXCEL</v-btn>
                                                </div>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.thick`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.thick) + ' mm'}}
                                    </template>
                                    <template v-slot:[`item.width`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.width) + ' mm' }}
                                    </template>
                                    <template v-slot:[`item.Length_OnHand`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.Length_OnHand) + ' m / ' + $store.getters.convertToCurrency(item.Wgt_OnHand) + ' kg' }}
                                    </template>
                                    <template v-slot:[`item.Length_Reserve`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.Length_Reserve) + ' m / ' + $store.getters.convertToCurrency(item.Wgt_Reserver) + ' kg'}}
                                    </template>
                                    <template v-slot:[`item.Length_unrelease`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.Length_unrelease) + ' m / ' + $store.getters.convertToCurrency(item.Wgt_unrelease) + ' kg'}}
                                    </template>
                                    <template v-slot:[`item.Length_Avail`]="{ item }">
                                        <!-- <tr v-if="item.Length_Avail < 0 || item.Wgt_Avail < 0" style="background-color: red; color: white;">
                                            {{ $store.getters.convertToCurrency(item.Length_Avail) + ' m / ' + $store.getters.convertToCurrency(item.Wgt_Avail) + ' kg'}}
                                        </tr>
                                        <tr v-else>
                                            {{ $store.getters.convertToCurrency(item.Length_Avail) + ' m / ' + $store.getters.convertToCurrency(item.Wgt_Avail) + ' kg'}}
                                        </tr> -->
                                        {{ $store.getters.convertToCurrency(item.Length_Avail) + ' m / ' + $store.getters.convertToCurrency(item.Wgt_Avail) + ' kg'}}
                                    </template>
                                    <template v-slot:[`item.Length_PO`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.Length_PO) + ' m / ' + $store.getters.convertToCurrency(item.Wgt_po) + ' kg'}}
                                    </template>
                                    <template v-slot:[`item.Length_OTW`]="{ item }">
                                        {{ $store.getters.convertToCurrency(item.Length_otw) + ' m / ' + $store.getters.convertToCurrency(item.Wgt_otw) + ' kg'}}
                                    </template>
                                    <!-- <template v-slot:[`item.available`]="{ item }">
                                        <div v-if="item.available == 'N'">
                                            <v-chip color="red" dark>{{ item.available }}</v-chip>
                                        </div>
                                    </template> -->
                                </v-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    </div>

    
</template>

<script>

import exportFromJSON from "export-from-json"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'Reports',
                disabled: false,
                href: '#',
                },
                {
                text: 'Material Availability',
                disabled: true,
                href: '#',
                },
            ],
            millModel: [],
            millLists: [],
            materialModel: [],
            materialLists: [],
            gradeModel: [],
            gradeLists: [],
            colorModel: [],
            colorLists: [],
            thicknessModel: [],
            thicknessLists: [],
            widthModel: [],
            widthLists: [],
            qualityModel: [],
            qualityLists: [],
            categoryModel: '',
            categoryLists: [],
            availabilityModel: '',
            availabilityLists: [
                {
                    value: 'Y',
                    text: 'AVAILABLE',
                },
                {
                    value: 'N',
                    text: 'NOT AVAILABLE',
                },
            ],
            regularModel : '',
            regularLists: [],
            itemLists: [],
            headers: [
                { text: 'MILL', value: 'mill_id', align: 'left', class: 'secondary--text blue lighten-5' },
                { text: 'MATERIAL', value: 'jenis', align: 'left', class: 'secondary--text blue lighten-5' },
                { text: 'GRADE', value: 'grade_id', align: 'left', class: 'secondary--text blue lighten-5' },
                { text: 'QUALITY', value: 'quality_id', align: 'center', class: 'secondary--text blue lighten-5' },
                { text: 'COLOR', value: 'NamaWarna', align: 'left', class: 'secondary--text blue lighten-5' },
                { text: 'THICKNESS', value: 'thick', align: 'right', class: 'secondary--text blue lighten-5' },
                { text: 'WIDTH', value: 'width', align: 'right', class: 'secondary--text blue lighten-5' },
                { text: 'ON HAND', value: 'Length_OnHand', align: 'right', class: 'secondary--text blue lighten-5' },
                { text: 'RESERVE', value: 'Length_Reserve', align: 'right', class: 'secondary--text blue lighten-5' },
                { text: 'UNRELEASE', value: 'Length_unrelease', align: 'right', class: 'secondary--text blue lighten-5' },
                { text: 'AVAILABLE', value: 'Length_Avail', align: 'right', class: 'secondary--text blue lighten-5' },
                { text: 'PO', value: 'Length_PO', align: 'right', class: 'secondary--text blue lighten-5' },
                { text: 'OTW', value: 'Length_OTW', align: 'right', class: 'secondary--text blue lighten-5' },
                { text: 'AVAILABILITY', value: 'available', align: 'left', class: 'secondary--text blue lighten-5' }
            ],
            searchItem: '',
            loadingDatatable: false,
            totalLengthOnHand: '',
            totalWgtOnHand: '',
            totalLengthReserve: '',
            totalWgtReserve: '',
            totalLengthUnrelease: '',
            totalWgtUnrelease: '',
            totalLengthAvail: '',
            totalWgtAvail: '',
            rdOrderOnlyModel: 'N',
            exportToExcelLists: []
        }
    },
    async mounted(){
        
        this.initialize()
        document.querySelector(".cardResult").style.display = "none";

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.categoryLists= [
                    { category: 'ROOF', },
                    { category: 'TRUSS' },
                ]

                this.regularLists= [
                    { text: 'REGULAR', value: 'Y' },
                    { text: 'NON REGULAR', value: 'N' },
                ]

                this.millLists = res.data.mill
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality
                this.$store.dispatch('setOverlay', false)
            })
        },

        async getData(){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                return false
            }
    
            this.itemLists = []
            this.exportToExcelLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "block";

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability/getData`, { 

                    mill: this.millModel ? this.millModel : '',
                    material: this.materialModel ? this.materialModel : [],
                    grade: this.gradeModel ? this.gradeModel : [],
                    color: this.colorModel ? this.colorModel : [],
                    thickness: this.thicknessModel ? this.thicknessModel : [],
                    width: this.widthModel ? this.widthModel : [],
                    quality: this.qualityModel ? this.qualityModel : [],
                    product: this.categoryModel ? this.categoryModel : '',
                    regular: this.regularModel ? this.regularModel : '',
                    availability: this.availabilityModel ? this.availabilityModel : '',
                    orderOnly : this.rdOrderOnlyModel ? this.rdOrderOnlyModel : ''
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.exportToExcelLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    this.totalLengthOnHand = res.data.totalLengthOnHand
                    this.totalWgtOnHand = res.data.totalWgtOnHand/1000
                    this.totalLengthReserve = res.data.totalLengthReserve
                    this.totalWgtReserve = res.data.totalWgtReserve/1000
                    this.totalLengthUnrelease = res.data.totalLengthUnrelease
                    this.totalWgtUnrelease = res.data.totalWgtUnrelease/1000
                    this.totalLengthAvail = res.data.totalLengthAvail
                    this.totalWgtAvail = res.data.totalWgtAvail/1000
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    document.querySelector(".cardResult").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                    this.totalLengthOnHand = 0
                    this.totalWgtOnHand = 0
                    this.totalLengthReserve = 0
                    this.totalWgtReserve = 0
                    this.totalLengthUnrelease = 0
                    this.totalWgtUnrelease = 0
                    this.totalLengthAvail = 0
                    this.totalWgtAvail = 0
                }
            })

        },


        async millOnChange(id){

            this.regularModel = ''
            this.categoryModel = ''
            this.materialModel = []
            this.gradeModel = []
            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)
            
            await axios.post(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability/optionOnChange`, { 

                mill: this.millModel,

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async materialOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.materialModel = []

                return false
            }

            this.gradeModel = []
            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async gradeOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.gradeModel = []

                return false
            }

            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: this.materialModel ? this.materialModel : [],
                grade: id             

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async colorOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.colorModel = []

                return false
            }

            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: this.materialModel ? this.materialModel : [],
                grade: this.gradeModel ? this.gradeModel : [],
                color: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {

                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async thicknessOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.thicknessModel = []

                return false
            }

            this.widthModel = []
            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: this.materialModel ? this.materialModel : [],
                grade: this.gradeModel ? this.gradeModel : [],
                color: this.colorModel ? this.colorModel : [],
                thickness: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async widthOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.widthModel = []

                return false
            }

            this.qualityModel = []

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability/optionOnChange`, { 

                mill: this.millModel ? this.millModel : [],
                regular: this.regularModel ? this.regularModel : '',
                product: this.categoryModel ? this.categoryModel : '',
                material: this.materialModel ? this.materialModel : [],
                grade: this.gradeModel ? this.gradeModel : [],
                color: this.colorModel ? this.colorModel : [],
                thickness: this.thicknessModel ? this.thicknessModel : [],
                width: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async qualityOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.qualityModel = []

                return false
            }

        },

        async categoryOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.categoryModel = []

                return false
            }

            this.$store.dispatch('setOverlay', true)

            this.materialModel = []
            this.gradeModel = []
            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability/optionOnChange`, { 

                mill: this.millModel ? this.millModel : '',
                regular: this.regularModel ? this.regularModel : '',
                product: id,


            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })

        },

        async regularOnChange(id){

            if(this.millModel == ''){
                Swal.fire({
                    text: 'Please choose mill first !',
                    icon: 'error',
                })

                this.regularModel = []

                return false
            }

            this.$store.dispatch('setOverlay', true)

            this.categoryModel = ''
            this.materialModel = []
            this.gradeModel = []
            this.colorModel = []
            this.thicknessModel = []
            this.widthModel = []
            this.qualityModel = []

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/MaterialAvailability/optionOnChange`, { 

                mill: this.millModel ? this.millModel : '',
                regular: id

            },{
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            })
            .then(res => {
                
                this.materialLists = res.data.material
                this.gradeLists = res.data.grade
                this.colorLists = res.data.color
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.qualityLists = res.data.quality

                this.$store.dispatch('setOverlay', false)

            })          

        },

        formatCurrency(value) {
            return value / 100
        },

        row_classes(item) {
            if (item.available == 'N' || item.Length_Avail < 0 || item.Wgt_Avail < 0) {
            return "red lighten-3";
            } 
        },

        exportToExcel(){

            const data = this.exportToExcelLists
            const fileName = "RawMat-Availability-Analysis-Excel-Data"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })

        },

    }
    
}
</script>

<style scoped>

.v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: #bde0fe !important;
        color: #fff !important;
    }

    .bgCustom2 {
        background-color: ghostwhite;
        color: #e3f2fd;
        margin-bottom: 5px;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5) !important;
        color:#e3f2fd !important;
       
    }
    
    .bgCustom16{
        background: #e3f2fd !important;
        color: #e3f2fd;
    
    }

    .container{
        margin-bottom: 60px !important;
    }

    .v-card {
        display: flex !important;
        flex-direction: column;
    }

    .v-card__text {
        flex-grow: 1;
        overflow: auto;
    }

    div.scrollDown {
        margin: 4px, 4px;
        padding: 4px;
        width: 100%;
        height: 120px;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: justify;
        border-style: solid;
        border-radius: 5px;
        border-color: rgb(95, 185, 207);
        border-width: 2px;
    }
    
    .fontCilik{
        font-family:'Segoe UI';
        font-size: 0.7rem !important ;
        font-weight: bold;
    }

    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
        font-weight:bold;
        font-size:12px;
    }

    .red_row {
        background-color: rgb(255, 134, 134);
    }

</style>